import AnamneseQuestionRepository from '@/shared/http/repositories/socialProject/anamnese-question'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import NewQuestions from '../components/questions.vue'
import QuestionModel from '@/shared/models/question'
import { IsSuccessCode } from '@/shared/utils/API'
import {toast, loading } from '@/shared/utils/UIHelper'

export default {
  name: 'NovaQuestão',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [NewQuestions.name]: NewQuestions
  },

  data: () => ({
    title: 'Questão',
    anamneseQuestion: new QuestionModel(),
    isNew: true
  }),

  provide() {
    return { parentValidator: this.$validator }
  },

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Questões',
            href: this.$router.resolve({ name: 'Questions' }).href
          },
          {
            text: 'Nova questão',
            active: true
          }
        ]
      }
    }
  },

  methods: {
    create(anamneseQuestion) {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
          return
        }

        
        loading.push()
        this.anamneseQuestion.anamneseId = this.$route.params.anamneseid
        AnamneseQuestionRepository.Create(anamneseQuestion)
          .then(res => {
            if (!IsSuccessCode(res)) return Promise.reject()
  
            loading.pop()
            toast.success('Questão criada com sucesso', 'NOVA QUESTÃO')
            Promise.resolve()
            return this.$router.replace({ name: 'Questions' })
          })
          .catch(() => {
            loading.pop()
            toast.error('Erro ao criar questão', 'ERRO')
          })
      })
    }
  }
}
